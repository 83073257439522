import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { CheveronRight } from 'styled-icons/heroicons-outline/CheveronRight';
import { CheveronLeft } from 'styled-icons/heroicons-outline/CheveronLeft';

import { colors } from '@oxygen/theme';

const BoxShadow = css`
  box-shadow: 0 9px 7px 0 rgba(0, 0, 0, 0.06), 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  @media (hover: hover) {
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 11px 9px -1px rgba(0, 0, 0, 0.06),
        0 5px 6px -1px rgba(0, 0, 0, 0.15);
    }
  }
`;

const OldButton = styled.a`
  border-radius: 4px;
  color: ${colors.white};
  background-color: ${colors.coral};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 2.9333;
  outline: none;
  height: 44px;
  padding: 0 16px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translateY(0);
  transition: transform 0.1s ease-out;
  width: 100%;
  max-width: 380px;
  ${BoxShadow};
  > svg {
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    height: 20px;
    width: 20px;
  }
  &:hover {
    text-decoration: none;
  }
`;

const OutlineButton = css`
  border: 1px solid ${colors.coral};
  color: ${colors.coral};

  &:visited {
    border: 1px solid ${colors.coral};
    color: ${colors.coral};
  }

  &:hover,
  &:active {
    border: 1px solid ${colors.pool};
    color: ${colors.pool};
  }
`;

const SolidButton = css`
  border: 1px solid ${colors.coral};
  background-color: ${colors.coral};
  color: ${colors.white};

  &:visited {
    background-color: ${colors.coral};
  }

  &:hover,
  &:active {
    border: 1px solid ${colors.rose};
    background-color: ${colors.rose};
  }
`;

const NewButton = styled.a`
  border-radius: 1px;
  display: inline-block;
  font-size: 2rem;
  line-height: 1.2;
  padding: 1.6rem 2.4rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  ${({ variant }) => (variant === 'outline' ? OutlineButton : SolidButton)}
`;

/**
 * Link button.
 *
 * @param children
 * @param className
 * @param newBrand This is to include the new brand but defaults to old.
 * @param variant solid|outline
 * @param rest
 * @returns {*}
 * @constructor
 */
const LinkButton = React.forwardRef(
  ({ back, children, className, newBrand, variant = 'solid', ...rest }, ref) =>
    newBrand ? (
      <NewButton className={className} ref={ref} variant={variant} {...rest}>
        {back && <CheveronLeft size={20} />}
        {children}
        {!back && <CheveronRight size={20} />}
      </NewButton>
    ) : (
      <OldButton className={className} ref={ref} {...rest}>
        {children}
      </OldButton>
    )
);

LinkButton.propTypes = {
  back: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  newBrand: PropTypes.bool
};

export default LinkButton;
